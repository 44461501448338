<template>
  <v-card
    width="100%"
    flat
  >
    <v-row no-gutters>
      <v-card-title :style="{ borderBottom: '1px solid #ccc', width: '100%', height: '60px', fontSize: '2.5vh' }">
        {{ title }}
      </v-card-title>
    </v-row>
    <v-row
      no-gutters
      :style="{ height: '88%' }"
      class="pt-2"
    >
      <v-col>
        <v-row no-gutters>
          <v-col
            sm="10"
            md="10"
            lg="4"
            :cols="$vssWidth < 600 ? 10 : 8"
            class="d-flex align-center"
          >
            Utiliza termo de garantia:
          </v-col>
          <v-col
            sm="2"
            md="2"
            lg="2"
            :cols="$vssWidth < 600 ? 2 : 8"
            class="pt-2"
          >
            <v-row no-gutters>
              <v-switch
                inset
              />              
            </v-row>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col
            sm="12"
            md="12"
            lg="8"
            :cols="$vssWidth < 600 ? 12 : 8"
            class="d-flex align-center"
          >
            Variáveis:
          </v-col>
          
          <v-col             
            sm="1"
            md="1"
            lg="1"
            :cols="$vssWidth < 600 ? 1 : 8"
            class="d-flex align-center"
          >
            <v-tooltip
              top
            >
              <template #activator="{ attrs, on }">
                <v-icon
                  v-show="$vssWidth >= 600"                  
                  v-bind="attrs"
                  :style="{ paddingLeft: $vssWidth > 600 && $vssWidth < 1264 ? '20px' : '' }"
                  class="pl-7"
                  v-on="on"
                >
                  info
                </v-icon>
              </template>
              <span>
                Duplo clique para adicionar variável ao texto.
              </span>
            </v-tooltip>
          </v-col>
          <v-col
            sm="12"
            md="12"
            lg="4"
            :cols="$vssWidth < 600 ? 12 : 8"
            class="pt-2"
          >
            <v-card
              max-width="300"
              tile
            >
              <v-list dense>
                <v-list-item-group
                  v-model="selectedItem"
                  color="primary"
                >
                  <v-list-item
                    v-for="(item, i) in items"
                    :key="i"
                  >
                    <v-list-item-icon />
                    <v-list-item-content>
                      <v-list-item-title v-text="item.text" />
                    </v-list-item-content>
                  </v-list-item>
                </v-list-item-group>
              </v-list>
            </v-card>
          </v-col>          
          <v-col
            sm="12"
            md="12"
            lg="5"
            :cols="$vssWidth < 600 ? 12 : 8"
            class="d-flex align-center"
          >
            Termo de garantia - Texto padrão para impressão:
          </v-col>
          <v-col
            sm="12"
            md="12"
            lg="8"
            :cols="$vssWidth < 600 ? 12 : 8"
            class="pt-2"
          >
            <v-textarea
              name=""
              label=""
              background-color="amber lighten-4"
              value="Garantia"
            />
          </v-col>
        </v-row>               
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
      default: '',
    },
  },
  data: () => ({
    selectedItem: 1,
    items: [
      { text: 'Nome do cliente' },
      { text: 'RG' },
      { text: 'CPF/CNPJ' },
      { text: 'Valor do frete' },
      { text: 'Telefone' },
      { text: 'Endereço' },
    ],
  }),
  watch: {

  },
  mounted() {

  },
  methods: {

  },

}
</script>